<template>
  <div class="mod-green_application_information}">
    <div>
      <el-card>
        <div slot="header" class="clearfix">
          <span>{{ title }}影像件</span>
        </div>
        <div>
          <div class="uploadImageContainer">
            <div
              v-for="item in imageList"
              :key="item.id"
              class="uploadImageContainer_left"
            >
              <div v-if="item.showFlag">
                <div class="">
                  <div>
                    <div class="icon-Con">
                      <div class="icon" v-if="item.required">*</div>
                      <div>
                        <h3>{{ item.name }}</h3>
                      </div>
                    </div>
                    <div @click="fileTypeClick(item)">
                      <el-upload
                        :action="url"
                        list-type="picture-card"
                        multiple
                        :file-list="item.fileList"
                        :before-upload="beforeUploadHandle"
                        :on-success="successHandle"
                        :on-preview="handlePictureCardPreview"
                        :on-remove="handleRemove"
                      >
                        <i class="el-icon-plus"></i>
                      </el-upload>
                      <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="" />
                      </el-dialog>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { showImageList } from '../../utils/addService';
export default {
  data() {
    return {
      url: '',
      num: 0,
      dialogVisible: false, //图片预览弹窗
      dialogImageUrl: '', //图片预览地址
      title: '',
      diagnosisProofList: [],
      imageList: [],
      imgList: [],
      dataForm: {},
      fileType: '',
      rightId: '',
    };
  },
  components: {},
  created() {
    this.init();
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.url = `${
        window.SITE_CONFIG['apiURL']
      }/sys/oss/upload?token=${sessionStorage.getItem('token')}`;
      // this.url = `https://miusapitest.miaoinstech.com/sys/oss/upload?token=${sessionStorage.getItem("token")}`;
      this.num = 0;
      // let dataForm = {};
      // this.initChildFn(dataForm);
    },
    initChildFn(dataForm) {
      this.dataForm = {
        ...this.dataForm,
        ...dataForm,
      };
      this.imageList = showImageList(dataForm.rightId);

      this.rightId = dataForm.rightId;
      // this.rightId = "1";
      // this.imageList = showImageList("1");
    },
    fileTypeClick(item) {
      console.log(item);
      this.fileType = item.fileType;
    },
    // 上传之前
    beforeUploadHandle(file) {
      var FileExt = file.name.replace(/.+\./, '');
      if (
        ['jpg', 'jpeg', 'png', 'gif', 'pdf'].indexOf(FileExt.toLowerCase()) ===
        -1
      ) {
        this.$message({
          type: 'warning',
          // message: '请上传后缀名为jpg、png、txt、pdf、doc、docx、xlsx、zip或rar的附件！'
          message: '请上传后缀名为jpg、png、pdf的附件！',
        });
        return false;
      }
      this.num++;
    },
    // 诊断证明
    successHandle(res, file, fileList) {
      console.log(res);
      console.log(file);
      let data = {};
      data.uid = file.uid;
      data.fileName = file.name;
      data.fileUrl = res.data.src;
      data.fileType = this.fileType;
      if (
        this.rightId === '4' ||
        this.rightId === '5' ||
        this.rightId === '13'
      ) {
        data.type = 2;
      } else {
        data.type = 1;
      }
      this.imgList.push(data);
      let imageList = [...this.imageList];
      for (let i in imageList) {
        if (this.fileType == imageList[i].fileType) {
          let fileObj = {};
          fileObj.fileName = file.name;
          fileObj.url = res.data.src;
          fileObj.fileType = this.fileType;
          fileObj.type = data.type;
          fileObj.uid = file.uid;
          imageList[i].fileList.push(fileObj);
        }
      }
      this.imageList = [...imageList];
      console.log('imageList', imageList);
      console.log('imgList', this.imgList);
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.num--;
      if (this.num === 0) {
        this.$message({
          message: '上传成功',
          type: 'success',
          duration: 500,
          onClose: () => {
            this.$emit('childImgFn', this.imgList);
          },
        });
      }
    },
    handleRemove(file, fileList) {
      console.log('file', file);
      // console.log("fileList", fileList);
      let imageList = this.imageList;
      // let imgList = this.imgList;
      for (let i in imageList) {
        if (this.fileType == imageList[i].fileType) {
          imageList[i].fileList.splice(
            imageList[i].fileList.findIndex((item) => item.uid === file.uid)
          );
        }
      }
      this.imageList = [...imageList];
      this.imgList.splice(
        this.imgList.findIndex((item) => item.uid === file.uid),
        1
      );
      // console.log("imageList", this.imageList);
      // console.log("imgList", this.imgList);
      this.$message({
        message: '删除成功',
        type: 'success',
        duration: 500,
        onClose: () => {
          this.$emit('childImgFn', this.imgList);
        },
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 被保险人身份证明
  },
};
</script>
<style scoped>
.clearfix {
  font-size: 20px;
  font-weight: 700;
}
.el-card {
  border-top: 3px solid rgba(3, 165, 151, 0.616);
}
.el-card /deep/.el-card__body {
  padding-bottom: 0px;
}
.uploadImageContainer {
  /* display: flex; */
  margin-bottom: 20px;
}
.uploadImageContainer_left {
  flex: 50%;
}
.uploadImageContainer_right {
  flex: 50%;
}
.vertical_border {
  border-left: 1px solid #ccc;
}
.inspection_report {
  margin-top: 30px;
}
.uploadImageContainer_right > div {
  margin-left: 30px;
}
h3 {
  color: rgb(114, 113, 113);
}
.medicalReport {
  margin-bottom: 30px;
}
</style>
<style lang="scss" scoped>
.icon-Con {
  display: flex;
  align-items: center;
  // height: 1px;
  .icon {
    color: red;
  }
}
</style>

<template>
  <div class="addService_application">
    <el-card class="">
      <div slot="header" class="clearfix">
        <span>{{ title }}信息</span>
      </div>
      <div>
        <el-form
          :inline="true"
          :model="rightInfo"
          :rules="rightInfoRules"
          ref="rightInfo"
          class="demo-form-inline"
        >
          <el-form-item :label="startDateName" prop="applyHospitalStartDate">
            <el-date-picker
              v-model="rightInfo.applyHospitalStartDate"
              type="date"
              value-format="yyyy-MM-dd"
              :placeholder="startDateName"
              @blur="blurChange"
            >
            </el-date-picker>
          </el-form-item>
          <!-- <el-form-item v-if="showEndDate" :label="EndDateName" prop="applyHospitalEndDate">
            <el-date-picker v-model="rightInfo.applyHospitalEndDate" type="date" value-format="yyyy-MM-dd" :placeholder="startDateName" @blur="blurChange"> </el-date-picker>
          </el-form-item> -->
          <el-form-item label="地区" prop="province">
            <el-cascader
              v-model="area"
              :options="options"
              :props="{ expandTrigger: 'hover' }"
              @change="handleChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item :label="applyHospitalName" prop="applyHospital">
            <el-input
              v-model="rightInfo.applyHospital"
              :placeholder="applyHospitalName"
              @blur="blurChange"
            ></el-input>
          </el-form-item>
          <el-form-item :label="applyDepartmentName" prop="applyDepartment">
            <el-input
              v-model="rightInfo.applyDepartment"
              :placeholder="applyDepartmentName"
              @blur="blurChange"
            ></el-input>
          </el-form-item>
          <el-form-item label="护理天数" prop="applyNursingDays">
            <el-select
              disabled
              v-model="rightInfo.applyNursingDays"
              placeholder="请选择护理天数"
              @blur="blurChange"
            >
              <el-option
                v-for="item in applyNursingDaysOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="服务地址" prop="address">
            <el-input
              v-model="rightInfo.address"
              placeholder="服务地址"
              @blur="blurChange"
            ></el-input>
          </el-form-item>
          <el-form-item label="需求描述" prop="appealDesc">
            <el-input
              v-model="rightInfo.appealDesc"
              type="textarea"
              placeholder="需求描述"
              @blur="blurChange"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
// import { regionData } from "element-china-area-data";
import { treeDataTranslate } from '@/utils';
export default {
  data() {
    return {
      startDateName: '预约日期',
      endDateName: '预约结束日期',
      applyHospitalName: '申请医院',
      applyDepartmentName: '申请科室',
      EndDateName: '出院时间',
      rightInfo: {
        applyHospitalStartDate: '',
        applyHospitalEndDate: '',
        applyHospital: '',
        applyDepartment: '',
        applyAdvanceMoney: '',
        province: '',
        city: '',
        district: '',
        illnessNote: '',
        medicareCard: '',
        address: '',
        applyNursingDays: '',
        applyNursingRank: '1',
        serveType: '',
        therapyAddress: '',
        mailingAddress: '',
        contactName: '',
        contactMobile: '',
        appealDesc: '',
        illnessNote: '',
        doctorName: '',
        payBank: '',
        payBankCard: '',
        payName: '',
        applyAdvanceMoney: '',
      },
      area: [],
      options: [],

      showFieldArray: [],
      //   护理等级
      applyNursingRankOptions: [
        { id: '1', name: '一级' },
        { id: '2', name: '二级' },
        { id: '3', name: '三级' },
      ],
      //   护理天数
      applyNursingDaysOptions: [
        { id: '1', name: '一天(24小时)' },
        { id: '2', name: '二天' },
        { id: '3', name: '三天' },
        { id: '26', name: '26天（包月）' },
      ],
      //   服务类型
      serveTypeOptions: [
        { id: '1', name: '中医上门理疗', code: 'door' },
        { id: '2', name: '中医线下门店理疗', code: 'self' },
      ],
      //   门店地址
      therapyAddressList: [],
      title: '',
    };
  },
  computed: {
    rightInfoRules() {
      return {
        applyHospitalStartDate: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        applyHospital: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        applyDepartment: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        province: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
      };
    },
  },
  created() {},
  methods: {
    getAreaList() {
      this.$http
        .get(`/sys/region/tree`)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          let dataList = res.data;
          for (let i in dataList) {
            dataList[i].value = dataList[i].id;
            dataList[i].label = dataList[i].name;
          }

          let dataList2 = treeDataTranslate(dataList);
          //  console.log('省市区',dataList2)
          //  console.log('省市区',Object.prototype.toString.call(dataList2))
          this.options = dataList2;
          // console.log("省市区", this.options);
        })
        .catch(() => {});
    },
    initChildFn(rightInfo, dataForm) {
      console.log('测试dataForm', dataForm);
      console.log('测试rightInfo', rightInfo);
      this.title = dataForm.aliasRightName;
      this.rightInfo = { ...rightInfo };
      switch (dataForm.rightId) {
        case '58':
        case '59':
        case '60':
        case '61':
          this.rightInfo.applyNursingDays = '1';
          break;
        case '62':
        case '63':
        case '64':
        case '65':
          this.rightInfo.applyNursingDays = '26';
          break;
      }
      this.getAreaList();
    },
    handleChange(e) {
      this.rightInfo.province = e[0];
      this.rightInfo.city = e[1];
      this.rightInfo.district = e[2];
    },
    // // 获取线下门店地址
    getTherapyList() {
      let params = {
        province: this.rightInfo.province,
        city: this.rightInfo.city,
        district: this.rightInfo.district,
      };
      if (!params.province) {
        return this.$message.error('请先选择地区');
      }
      this.$http
        .get(`/sys/systherapyregion/getTherapyList`, { params })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.therapyAddressList = res.data;
        })
        .catch(() => {});
    },
    blurChange() {
      this.$emit('childFn', this.rightInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
.addService_application {
  .el-card {
    border-top: 3px solid rgba(3, 165, 151, 0.616);
  }
  .clearfix {
    font-size: 20px;
    font-weight: 700;
  }
}
</style>

<template>
  <div class="mod-green_basic_information}">
    <div>
      <!-- <div>用户基本信息</div> -->
      <el-card>
        <div slot="header" class="clearfix">
          <span>用户基本信息</span>
        </div>
        <div class="basic_information">
          <el-form :inline="true" :model="userInfo" ref="userInfo" :rules="dataRule" class="demo-form-inline">
            <el-form-item label="用户姓名" prop="proposerName">
              <el-select v-model="proposerName" value-key="id" placeholder="请选择" @change="blurChangess">
                <el-option v-for="item in userContactsList" :key="item.id" :label="item.name" :value="item"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="证件类型" prop="certificateType">
              <el-select v-model="userInfo.certificateType" placeholder="请选择" @blur="blurChange">
                <el-option v-for="item in IdTypeData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="证件号码" prop="certificateNo" >
              <el-input v-model="userInfo.certificateNo" placeholder="证件号码" @blur="blurChange"></el-input>
            </el-form-item>
            <el-form-item label="手机号码" prop="mobile" >
              <el-input v-model="userInfo.mobile" placeholder="手机号码" @blur="blurChange"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  props: ["green"],
  data() {
    return {
      userInfo: {
        proposerName: "",
        certificateType: "",
        certificateNo: "",
        mobile: "",
      },
      proposerName:{},
      userContactsList: [],
      IdTypeData: [
        { id: '101', name: '居民身份证' },
        { id: '102', name: '军官证' },
        { id: '103', name: '军官退休证' },
        { id: '104', name: '护照' },
      ],
    };
  },
  // computed: {
  //   greenss: function() {
  //     console.log('dd', this.green);
  //   },
  // },
  components: {
    // AddOrUpdate
  },
  computed: {
    dataRule() {
      return {
        proposerName: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
        certificateNo: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
          {
            pattern: /^1(3|4|5|7|8)\d{9}$/,
            message: "手机号码格式不对，请重新输入",
            trigger: "blur",
          },
        ],
      };
    },
  },
  // created() {
  //   this.getuserInfo();
  // },
  methods: {
    // 父组件传参
    initChildFn(userInfo, contactsEntityList) {
      this.userInfo = { ...userInfo };
      
      this.userContactsList = [...contactsEntityList];
      for (let i in contactsEntityList) {
        if (userInfo.proposerName == contactsEntityList[i].name) {
          this.userInfo.mobile =contactsEntityList[i].mobile
          let proposerName = {
            id:contactsEntityList[i].id
          }
          this.proposerName = proposerName
        }
      }
      console.log("userInfo", userInfo);
      console.log("contactsEntityList", contactsEntityList);
    },
    blurChange(e){
      this.$emit('childUserFn',this.userInfo)
    },
    blurChangess(e){
      this.userInfo.mobile=e.mobile
      this.userInfo.proposerName=e.name
      this.userInfo.certificateType=e.certificateType
      this.userInfo.certificateNo=e.certificateNo
      this.$emit('childUserFn',this.userInfo)
    }
  },
};
</script>
<style scoped>
.clearfix {
  font-size: 20px;
  font-weight: 700;
}
.el-card {
  border-top: 3px solid rgba(3, 165, 151, 0.616);
}
.el-card /deep/.el-card__body {
  padding-bottom: 0px;
}
.basic_information {
  /* width: 90%; */
  /* border: 1px solid #cccccc; */
}
.input {
  display: inline-block;
}
.demo-form-inline {
  margin-left: 20px;
}
.el-form--inline .el-form-item {
  margin-right: 30px;
}
</style>

export function startDateNameFn(rightId) {
  let startDateName = "申请日期";
  switch (rightId) {
    case "2":
    case "30":
    case "31":
    case "34":
    case "47":
    case "49":
      startDateName = "意向日期";
      break;
    case "3":
    case "20":
      startDateName = "预约日期";
      break;
    case "11":
    case "12":
      startDateName = "就医日期";
      break;
    case "17":
    case "18":
      startDateName = "开单日期";
      break;
  }
  return startDateName;
}
export function applyHospitalFn(rightId) {
  let applyHospitalName = "申请医院";
  let applyDepartmentName = "申请科室";
  switch (rightId) {
    case "1":
    case "2":
    case "4":
    case "11":
    case "12":
      applyHospitalName = "所在医院";
      applyDepartmentName = "所在科室";
      break;
    case "3":
    case "17":
    case "18":
    case "19":
    case "29":
    case "30":
    case "31":
    case "32":
    case "33":
    case "34":
    case "47":
    case "49":
      applyHospitalName = "申请医院";
      applyDepartmentName = "申请科室";
      break;
  }
  let nameObj = {
    applyHospitalName: applyHospitalName,
    applyDepartmentName: applyDepartmentName,
  };
  return nameObj;
}

export function showStartDateFun(rightId) {
  switch (rightId) {
    case "2":
    case "3":
    case "11":
    case "12":
    case "17":
    case "18":
    case "20":
    case "30":
    case "31":
    case "34":
    case "47":
    case "49":
      return true;
      break;
    default:
      return false;
  }
}

export function showApplyHospitalNameFn(rightId) {
  switch (rightId) {
    case "1":
    case "2":
    case "3":
    case "4":
    case "11":
    case "12":
    case "17":
    case "18":
    case "19":
    case "29":
    case "30":
    case "31":
    case "32":
    case "33":
    case "34":
    case "47":
    case "49":
      return true;
      break;
    default:
      return false;
  }
}

export function showAreaFn(rightId) {
  switch (rightId) {
    case "1":
    case "2":
    case "3":
    case "4":
    case "11":
    case "12":
    case "17":
    case "18":
    case "19":
    case "20":
    case "29":
    case "30":
    case "31":
    case "32":
    case "33":
    case "34":
    case "47":
    case "49":
      return true;
      break;
    default:
      return false;
  }
}

export function showMedicareCardFn(rightId) {
  switch (rightId) {
    case "2":
    case "17":
    case "19":
    case "30":
    case "31":
    case "47":
    case "49":
      return true;
      break;
    default:
      return false;
  }
}
export function showOther(rightId) {
  let showApplyNursingDays = false;
  let showApplyNursingRank = false;
  let showServeType = false;
  let showTherapyAddress = false;
  let showAddress = false;
  let showMailingAddress = false;
  let showContactName = false;
  let showContactMobile = false;
  switch (rightId) {
    case "3":
      showApplyNursingDays = true;
      showApplyNursingRank = true;
      showAddress = true;
      break;
    case "20":
      showServeType = true;
      showTherapyAddress = true;
      break;
    case "48":
      showMailingAddress = true;
      showContactName = true;
      showContactMobile = true;
      break;
  }
  let otherObj = {
    showApplyNursingDays,
    showApplyNursingRank,
    showServeType,
    showTherapyAddress,
    showAddress,
    showMailingAddress,
    showContactName,
    showContactMobile,
  };
  return otherObj;
}

export function showDoctorNameFn(rightId) {
  switch (rightId) {
    case "1":
    case "2":
    case "19":
    case "29":
    case "30":
    case "31":
    case "32":
    case "33":
    case "47":
    case "49":
      return true;
      break;
    default:
      return false;
  }
}
export function showAppealDescFn(rightId) {
  switch (rightId) {
    case "3":
    case "4":
    case "10":
    case "20":
    case "23":
    case "24":
    case "25":
    case "26":
    case "34":
    case "35":
    case "36":
    case "37":
    case "38":
    case "39":
    case "40":
    case "41":
    case "43":
    case "44":
    case "46":
      return true;
      break;
    default:
      return false;
  }
}

export function showIllnessNoteFn(rightId) {
  switch (rightId) {
    case "1":
    case "2":
    case "11":
    case "12":
    case "18":
    case "29":
    case "32":
    case "33":
    case "47":
    case "49":
      return true;
      break;
    default:
      return false;
  }
}

export function showImageList(rightId) {
  let imageList = [
    { id: 1, fileList: [], name: "住院单", fileType: 1, showFlag: false,required:false },
    { id: 2, fileList: [], name: "检查单", fileType: 2, showFlag: false,required:false },
    { id: 3, fileList: [], name: "重疾诊断报告", fileType: 3, showFlag: false,required:false },
    { id: 4, fileList: [], name: "癌症诊断报告", fileType: 4, showFlag: false,required:false },
    { id: 5, fileList: [], name: "预约信息", fileType: 5, showFlag: false,required:false },
    { id: 6, fileList: [], name: "纸质保单/电子保单照片", fileType: 6, showFlag: false,required:false },
    { id: 7, fileList: [], name: "住院证照片", fileType: 7, showFlag: false,required:false },
    { id: 8, fileList: [], name: "押金单照片", fileType: 8, showFlag: false,required:false },
    { id: 9, fileList: [], name: "有效身份证照片（正反）", fileType: 9, showFlag: false,required:false },
    { id: 10, fileList: [], name: "门急诊病历（相关的）", fileType: 10, showFlag: false,required:false },
    { id: 11, fileList: [], name: "检查检验汉报告", fileType: 11, showFlag: false,required:false },
    { id: 12, fileList: [], name: "医保卡/就诊卡正反面照片", fileType: 12, showFlag: false,required:false },
    { id: 13, fileList: [], name: "二级及以上医院诊断证明", fileType: 13, showFlag: false,required:false },
    { id: 14, fileList: [], name: "缴费通知单", fileType: 14, showFlag: false,required:false },
    { id: 15, fileList: [], name: "诊断证明", fileType: 15, showFlag: false,required:false },
    { id: 16, fileList: [], name: "检查结果", fileType: 16, showFlag: false,required:false },
    { id: 17, fileList: [], name: "医学报告", fileType: 17, showFlag: false,required:false },
    { id: 18, fileList: [], name: "其他", fileType: 18, showFlag: true,required:false },
  ];
  for (let i in imageList) {
    switch (rightId) {
      case "1":
      case "18":
      case "29":
      case "32":
      case "33":
        imageList[0].showFlag = true;
        imageList[0].required = true;
        break;
      case "4":
        // imageList[0].showFlag = true;
        imageList[5].showFlag = true;
        imageList[6].showFlag = true;
        imageList[7].showFlag = true;
        imageList[8].showFlag = true;
        imageList[9].showFlag = true;
        imageList[10].showFlag = true;
        imageList[11].showFlag = true;
        imageList[12].showFlag = true;
        imageList[13].showFlag = true;
        imageList[6].required = true;
        imageList[7].required = true;
        imageList[8].required = true;

        break;
      case "10":
      case "40":
        imageList[1].showFlag = true;
        imageList[14].showFlag = true;
        imageList[15].showFlag = true;
        break;
      case "12":
      case "34":
        imageList[4].showFlag = true;
        imageList[4].required = true;
        break;
      case "17":
        imageList[1].showFlag = true;
        imageList[1].required = true;
        break;
      case "24":
        imageList[16].showFlag = true;
        imageList[16].required = true;
        break;
      case "30":
        imageList[2].showFlag = true;
        imageList[2].required = true;
        break;
      case "31":
        imageList[3].showFlag = true;
        imageList[3].required = true;
        break;
      case "47":
        imageList[9].showFlag = true;
        break;
      default:
        imageList[i].showFlag = false;
        imageList[17].showFlag = true;
    }
  }
  return imageList;
}

export function showAdavanceFn(rightId) {
  let advanceObj = {
    showAdavance: false,
    showStartDate: false,
    showEndDate: false,
    showArea: false,
    showApplyHospitalName: false,
    showApplyDepartment: false,
    showAppealDesc: false,
  };
  switch (rightId) {
    case "4":
    case "5":
      // return true
      (advanceObj.showAdavance = true),
        (advanceObj.showStartDate = true),
        (advanceObj.showEndDate = true),
        (advanceObj.showArea = true),
        (advanceObj.showApplyHospitalName = true),
        (advanceObj.showApplyDepartment = true),
        (advanceObj.showAppealDesc = true);
      break;
    default:
      // return false
      (advanceObj.showAdavance = false),
        (advanceObj.showStartDate = false),
        (advanceObj.showEndDate = false),
        (advanceObj.showArea = false),
        (advanceObj.showApplyHospitalName = false),
        (advanceObj.showApplyDepartment = false),
        (advanceObj.showAppealDesc = false);
  }
  return advanceObj;
}

export function rulesRight(params) {
  switch (params.rightId) {
    case "1":
    case "2":
    case "3":
    case "4":
    case "11":
    case "12":
    case "17":
    case "18":
    case "19":
    case "29":
    case "30":
    case "31":
    case "32":
    case "33":
    case "34":
    case "47":
    case "49":
      if (!params.province) return "地区不能为空";
      if (!params.applyHospital) return "医院不能为空";
      if (!params.applyDepartment) return "科室不能为空";
      // if (!params.doctorName) return "医生不能为空";
      break;
    case "10":
    case "23":
    case "24":
    case "25":
    case "26":
    case "35":
    case "36":
    case "37":
    case "38":
    case "39":
    case "40":
    case "41":
    case "43":
    case "44":
    case "46":
      if (!params.appealDesc) return "诉求描述不能为空";
      break;
    case "20":
      if (!params.province) return "地区不能为空";
      if (!params.serveType) return "服务方式不能为空";
      if (!params.therapyAddress) return "地址不能为空";
      if (!params.applyHospitalStartDate) return "日期不能为空";
      break;
    case "48":
      if (!params.mailingAddress) return "邮寄地址不能为空";
      if (!params.contactName) return "联系人不能为空";
      if (!params.contactMobile) return "联系电话不能为空";
      break;
    default:
      return "";
  }
}

export function imgRules(params) {
  let imageList = params.imgList
  switch (params.rightId) {
    case "1":
    case "18":
    case "29":
    case "32":
    case "33":
      if (imageList.length>0) {
        // for (let i in imageList) {
        //   if (imageList[i].fileType !== 1) return '住院单不能为空'
        // }
        let fileTypeList = []
        for (let i in imageList) {
          fileTypeList.push(imageList[i].fileType)
        }
        if (fileTypeList.indexOf(1)==-1)return '住院单不能为空'
      } else{
        return '住院单不能为空'
      }
      break;
    case "4":
      if (imageList.length>0) {
        console.log('imageList',imageList)
        let fileTypeList = []
        for (let i in imageList) {
          fileTypeList.push(imageList[i].fileType)
        }
        if (fileTypeList.indexOf(7)==-1)return '必填项不能为空'
        if (fileTypeList.indexOf(8)==-1)return '必填项不能为空'
        if (fileTypeList.indexOf(9)==-1)return '必填项不能为空'
      }else{
        return '影像件不能为空'
      }
      break;
    case "17":
      if (imageList.length>0) {
        // for (let i in imageList) {
        //   if (imageList[i].fileType !== 2) return '检查单不能为空'
        // }
        let fileTypeList = []
        for (let i in imageList) {
          fileTypeList.push(imageList[i].fileType)
        }
        if (fileTypeList.indexOf(2)==-1)return '住院单不能为空'
      }else{
        return '检查单不能为空'
      }
      break;
    case "30":
      if (imageList.length>0) {
        // for (let i in imageList) {
        //   if (imageList[i].fileType !== 3) return '重疾诊断报告不能为空'
        // }
        let fileTypeList = []
        for (let i in imageList) {
          fileTypeList.push(imageList[i].fileType)
        }
        if (fileTypeList.indexOf(3)==-1)return '住院单不能为空'
      }else{
        return '重疾诊断报告不能为空'
      }
      break;
    case "31":
      if (imageList.length>0) {
        // for (let i in imageList) {
        //   if (imageList[i].fileType !== 4) return '癌症诊断报告不能为空'
        // }
        let fileTypeList = []
        for (let i in imageList) {
          fileTypeList.push(imageList[i].fileType)
        }
        if (fileTypeList.indexOf(4)==-1)return '住院单不能为空'
      }else{
        return '癌症诊断报告不能为空'
      }
      break;
    case "34":
      if (imageList.length>0) {
        // for (let i in imageList) {
        //   if (imageList[i].fileType !== 6) return '预约信息不能为空'
        // }
        let fileTypeList = []
        for (let i in imageList) {
          fileTypeList.push(imageList[i].fileType)
        }
        if (fileTypeList.indexOf(6)==-1)return '住院单不能为空'
      }else{
        return '预约信息不能为空'
      }
      break;
    default:
      return "";
  }
}

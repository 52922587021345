<template>
  <div class="addService_application">
    <el-card class="">
      <div slot="header" class="clearfix">
        <span>{{ title }}信息</span>
      </div>
      <div>
        <el-form :inline="true" :model="rightInfo" :rules="rightInfoRules" ref="rightInfo" class="demo-form-inline">
          <el-form-item v-if="showStartDate" :label="startDateName" prop="applyHospitalStartDate">
            <el-date-picker v-model="rightInfo.applyHospitalStartDate" type="date" value-format="yyyy-MM-dd" :placeholder="startDateName" @blur="blurChange"> </el-date-picker>
          </el-form-item>
          <el-form-item v-if="showEndDate" :label="EndDateName" prop="applyHospitalEndDate">
            <el-date-picker v-model="rightInfo.applyHospitalEndDate" type="date" value-format="yyyy-MM-dd" :placeholder="startDateName" @blur="blurChange"> </el-date-picker>
          </el-form-item>
          <el-form-item v-if="showApplyHospitalName" :label="applyHospitalName" prop="applyHospital">
            <el-input v-model="rightInfo.applyHospital" :placeholder="applyHospitalName" @blur="blurChange"></el-input>
          </el-form-item>
          <el-form-item v-if="showApplyDepartment" :label="applyDepartmentName" prop="applyDepartment">
            <el-input v-model="rightInfo.applyDepartment" :placeholder="applyDepartmentName" @blur="blurChange"></el-input>
          </el-form-item>
          <el-form-item v-if="showArea" label="地区" prop="province">
            <el-cascader v-model="area" :options="options" :props="{ expandTrigger: 'hover' }" @change="handleChange"></el-cascader>
          </el-form-item>
          <!-- 垫付字段 -->

          <el-form-item v-if="showAdavance" label="垫付金额" prop="applyAdvanceMoney">
            <el-input v-model="rightInfo.applyAdvanceMoney" placeholder="垫付金额" @blur="blurChange"></el-input>
          </el-form-item>
          <el-form-item v-if="showAdavance" label="开户行" prop="payBank">
            <el-input v-model="rightInfo.payBank" placeholder="开户行" @blur="blurChange"></el-input>
          </el-form-item>
          <el-form-item v-if="showAdavance" label="银行卡号" prop="payBankCard">
            <el-input v-model="rightInfo.payBankCard" placeholder="银行卡号" @blur="blurChange"></el-input>
          </el-form-item>
          <el-form-item v-if="showAdavance" label="账户姓名" prop="payName">
            <el-input v-model="rightInfo.payName" placeholder="账户姓名" @blur="blurChange"></el-input>
          </el-form-item>
          <!-- <el-form-item label="保单号" prop="applyDepartment">
              <el-input v-model="rightInfo.applyDepartment" placeholder="保单号" @blur="blurChange"></el-input>
            </el-form-item> -->

          <el-form-item v-if="showDoctorName" label="开单医生" prop="doctorName">
            <el-input v-model="rightInfo.doctorName" placeholder="开单医生" @blur="blurChange"></el-input>
          </el-form-item>
          <el-form-item v-if="showMedicareCard" label="医保卡号" prop="medicareCard">
            <el-input v-model="rightInfo.medicareCard" placeholder="医保卡号" @blur="blurChange"></el-input>
          </el-form-item>
          <el-form-item v-if="showApplyNursingDays" label="护理天数" prop="applyNursingDays">
            <el-select v-model="rightInfo.applyNursingDays" placeholder="请选择护理天数" @blur="blurChange">
              <el-option v-for="item in applyNursingDaysOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="showApplyNursingRank" label="护理等级" prop="applyNursingRank">
            <el-select v-model="rightInfo.applyNursingRank" placeholder="请选择护理等级" @blur="blurChange">
              <el-option v-for="item in applyNursingRankOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="showServeType" label="服务类型" prop="serveType">
            <el-select v-model="rightInfo.serveType" placeholder="请选择服务类型" @blur="blurChange">
              <el-option v-for="item in serveTypeOptions" :key="item.id" :label="item.name" :value="item.code"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item v-if="showTherapyAddress" label="门店地址" prop="therapyAddress">
            <el-select filterable clearable v-model="rightInfo.therapyAddress" placeholder="请选择门店地址" @blur="blurChange">
              <el-option v-for="item in therapyAddressList" :key="item.id" :label="item.address" :value="item.address"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="showAddress" label="服务地址" prop="address">
            <el-input v-model="rightInfo.address" placeholder="服务地址" @blur="blurChange"></el-input>
          </el-form-item>
          <el-form-item v-if="showMailingAddress" label="邮寄地址" prop="mailingAddress">
            <el-input v-model="rightInfo.mailingAddress" placeholder="邮寄地址" @blur="blurChange"></el-input>
          </el-form-item>
          <el-form-item v-if="showContactName" label="联系人" prop="contactName">
            <el-input v-model="rightInfo.contactName" placeholder="联系人" @blur="blurChange"></el-input>
          </el-form-item>
          <el-form-item v-if="showContactMobile" label="联系电话" prop="contactMobile">
            <el-input v-model="rightInfo.contactMobile" placeholder="联系电话" @blur="blurChange"></el-input>
          </el-form-item>
          <el-form-item v-if="showAppealDesc" label="需求描述" prop="appealDesc">
            <el-input v-model="rightInfo.appealDesc" type="textarea" placeholder="需求描述" @blur="blurChange"></el-input>
          </el-form-item>
          <el-form-item v-if="showIllnessNote" label="病情描述" prop="illnessNote">
            <el-input v-model="rightInfo.illnessNote" type="textarea" placeholder="病情描述" @blur="blurChange"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  startDateNameFn,
  showAppealDescFn,
  showIllnessNoteFn,
  showDoctorNameFn,
  applyHospitalFn,
  showStartDateFun,
  showApplyHospitalNameFn,
  showMedicareCardFn,
  showOther,
  showAreaFn,
  showAdavanceFn,
} from "../../utils/addService.js";
// import { regionData } from "element-china-area-data";
import { treeDataTranslate } from "@/utils";
export default {
  data() {
    return {
      startDateName: "预约开始日期",
      endDateName: "预约结束日期",
      applyHospitalName: "申请医院",
      applyDepartmentName: "申请科室",
      EndDateName: "出院时间",
      showStartDate: false,
      showApplyHospitalName: false,
      showApplyDepartment: false,
      showArea: false,
      showMedicareCard: false,
      showApplyNursingDays: false,
      showApplyNursingRank: false,
      showServeType: false,
      showTherapyAddress: false,
      showAddress: false,
      showMailingAddress: false,
      showContactName: false,
      showContactMobile: false,
      showDoctorName: false,
      showAppealDesc: false,
      showIllnessNote: false,
      showEndDate: false,
      showAdavance: false,
      rightInfo: {
        applyHospitalStartDate: "",
        applyHospitalEndDate: "",
        applyHospital: "",
        applyDepartment: "",
        applyAdvanceMoney: "",
        province: "",
        city: "",
        district: "",
        illnessNote: "",
        medicareCard: "",
        address: "",
        applyNursingDays: "",
        applyNursingRank: "",
        serveType: "",
        therapyAddress: "",
        mailingAddress: "",
        contactName: "",
        contactMobile: "",
        appealDesc: "",
        illnessNote: "",
        doctorName: "",
        payBank: "",
        payBankCard: "",
        payName: "",
        applyAdvanceMoney: "",
      },
      area: [],
      options: [],
      showFieldArray: [],
      //   护理等级
      applyNursingRankOptions: [
        { id: "1", name: "一级" },
        { id: "2", name: "二级" },
        { id: "3", name: "三级" },
      ],
      //   护理天数
      applyNursingDaysOptions: [
        { id: "1", name: "一天" },
        { id: "2", name: "二天" },
        { id: "3", name: "三天" },
      ],
      //   服务类型
      serveTypeOptions: [
        { id: "1", name: "中医上门理疗", code: "door" },
        { id: "2", name: "中医线下门店理疗", code: "self" },
      ],
      //   门店地址
      therapyAddressList: [],
      title: "",
    };
  },
  computed: {
    rightInfoRules() {
      return {
        applyHospitalStartDate: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
        applyHospital: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
        applyDepartment: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
        province: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
      };
    },
  },
  methods: {
     getAreaList() {
      this.$http
        .get(`/sys/region/tree`)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          let dataList = res.data;
          for (let i in dataList) {
            dataList[i].value = dataList[i].id;
            dataList[i].label = dataList[i].name;
          }

          let dataList2 = treeDataTranslate(dataList);
          this.options = dataList2
        })
        .catch(() => {});
    },
    initChildFn(rightInfo, dataForm) {
      console.log("测试", dataForm);
      let showFieldArray = [];
      showFieldArray.push(dataForm.rightId);
      this.showFieldArray = showFieldArray;
      this.title = dataForm.aliasRightName;
      this.startDateName = startDateNameFn(dataForm.rightId);
      this.applyHospitalName = applyHospitalFn(dataForm.rightId).applyHospitalName;
      this.applyDepartmentName = applyHospitalFn(dataForm.rightId).applyDepartmentName;
      this.showStartDate = showStartDateFun(dataForm.rightId);
      this.showApplyHospitalName = showApplyHospitalNameFn(dataForm.rightId);
      this.showApplyDepartment = showApplyHospitalNameFn(dataForm.rightId);
      this.showArea = showAreaFn(dataForm.rightId);
      this.showMedicareCard = showMedicareCardFn(dataForm.rightId);
      this.showApplyNursingDays = showOther(dataForm.rightId).showApplyNursingDays;
      this.showApplyNursingRank = showOther(dataForm.rightId).showApplyNursingRank;
      this.showServeType = showOther(dataForm.rightId).showServeType;
      this.showTherapyAddress = showOther(dataForm.rightId).showTherapyAddress;
      this.showAddress = showOther(dataForm.rightId).showAddress;
      this.showMailingAddress = showOther(dataForm.rightId).showMailingAddress;
      this.showContactName = showOther(dataForm.rightId).showContact;
      this.showContactMobile = showOther(dataForm.rightId).showContactMobile;
      this.showDoctorName = showDoctorNameFn(dataForm.rightId);
      this.showAppealDesc = showAppealDescFn(dataForm.rightId);
      this.showIllnessNote = showIllnessNoteFn(dataForm.rightId);

      if (dataForm.rightId == "4" || dataForm.rightId == "5") {
        this.showAdavance = showAdavanceFn(dataForm.rightId).showAdavance;
        this.showStartDate = showAdavanceFn(dataForm.rightId).showStartDate;
        this.showEndDate = showAdavanceFn(dataForm.rightId).showEndDate;
        this.showArea = showAdavanceFn(dataForm.rightId).showArea;
        this.showApplyHospitalName = showAdavanceFn(dataForm.rightId).showApplyHospitalName;
        this.showApplyDepartment = showAdavanceFn(dataForm.rightId).showApplyDepartment;
        this.showAppealDesc = showAdavanceFn(dataForm.rightId).showAppealDesc;
      }
      console.log("showStartDate", this.showStartDate);
      this.getAreaList()
    },
    handleChange(e) {
      this.rightInfo.province = e[0];
      this.rightInfo.city = e[1];
      this.rightInfo.district = e[2];

      this.getTherapyList();
      this.$emit("childFn", this.rightInfo);
    },
    // // 获取线下门店地址
    getTherapyList() {
      let params = {
        province: this.rightInfo.province,
        city: this.rightInfo.city,
        district: this.rightInfo.district,
      };
      if (!params.province) {
        return this.$message.error("请先选择地区");
      }
      this.$http
        .get(`/sys/systherapyregion/getTherapyList`, { params })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.therapyAddressList = res.data;
        })
        .catch(() => {});
    },
    blurChange() {
      this.$emit("childFn", this.rightInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
.addService_application {
  .el-card {
    border-top: 3px solid rgba(3, 165, 151, 0.616);
  }
  .clearfix {
    font-size: 20px;
    font-weight: 700;
  }
}
</style>

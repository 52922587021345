<template>
  <div class="add_service">
    <!-- 权益卡信息 -->
    <div>
      <div>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>服务单信息</span>
          </div>
          <div>
            <el-form
              :model="dataForm"
              :inline="true"
              disabled
              ref="dataForm"
              :label-width="$i18n.locale === 'en-US' ? '130px' : '90px'"
            >
              <el-form-item label="用户权益id" prop="userRightId">
                <el-input
                  readonly
                  v-model="dataForm.userRightId"
                  placeholder="用户权益表id"
                ></el-input>
              </el-form-item>
              <el-form-item label="权益代码" prop="rightCode">
                <el-input
                  readonly
                  v-model="dataForm.rightCode"
                  placeholder="权益代码"
                ></el-input>
              </el-form-item>
              <el-form-item label="权益名称" prop="rightName">
                <div class="rightNameCon">
                  <el-input
                    readonly
                    v-model="dataForm.rightName"
                    placeholder="权益名称"
                  ></el-input>
                </div>
              </el-form-item>
              <el-popover
                :disabled="false"
                placement="bottom"
                width="600"
                trigger="hover"
                :content="dataForm.aliasRightDesc"
              >
                <img
                  class="tiShiImg"
                  slot="reference"
                  src="../../../assets/img/tishi.png"
                />
              </el-popover>
              <el-form-item label="权益别名" prop="aliasRightName">
                <el-input
                  readonly
                  v-model="dataForm.aliasRightName"
                  placeholder="权益包名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="权益包名称" prop="packageName">
                <el-input
                  readonly
                  v-model="dataForm.packageName"
                  placeholder="权益包名称"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </div>
    </div>
    <!-- 权益使用人信息 -->
    <div class="addService_content">
      <basicUser ref="basicUser" @childUserFn="methodsUserChange"></basicUser>
    </div>
    <!-- 权益字段信息 -->
    <div class="addService_content">
      <basicApplication
        v-if="!showExclusiveCare"
        ref="basicApplications"
        @childFn="methodsChange"
      ></basicApplication>
      <exclusiveCare
        v-if="showExclusiveCare"
        ref="exclusiveCare"
        @childFn="methodsChange"
      ></exclusiveCare>
    </div>
    <!-- 权益影像件信息 -->
    <div class="addService_content">
      <basicImage
        ref="basicImage"
        v-if="!showExclusiveCare"
        @childImgFn="methodsImgChange"
      ></basicImage>
    </div>
    <div class="addService_content submitButton">
      <el-button type="primary" @click="onSubmits()">提交</el-button>
    </div>
  </div>
</template>

<script>
import basicUser from '../../../components/addService/basic_user.vue';
import basicApplication from '../../../components/addService/basic_application.vue';
import exclusiveCare from '../../../components/addService/exclusiveCare.vue';
import basicImage from '../../../components/addService/basic_image_information.vue';
import { rulesRight, imgRules } from '../../../utils/addService';
export default {
  data() {
    return {
      dataForm: {
        rightId: '',
        rightClassify: 1,
        userRightId: '',
        rightCode: '',
        rightName: '',
        aliasRightName: '',
        packageName: '',
      },
      userInfo: {
        proposerName: '',
        certificateType: '',
        certificateNo: '',
        mobile: '',
      },
      userContact: [],
      rightInfo: {
        applyHospitalStartDate: '',
        applyHospitalEndDate: '',
        applyHospital: '',
        applyDepartment: '',
        province: '',
        city: '',
        district: '',
        illnessNote: '',
        medicareCard: '',
        address: '',
        applyNursingDays: '',
        applyNursingRank: '',
        serveType: '',
        therapyAddress: '',
        mailingAddress: '',
        contactName: '',
        contactMobile: '',
        appealDesc: '',
        illnessNote: '',
        doctorName: '',
        payBank: '',
        payBankCard: '',
        payName: '',
        applyAdvanceMoney: '',
      },
      imgList: [],
      showExclusiveCare: false,
    };
  },
  components: {
    basicUser,
    basicApplication,
    basicImage,
    exclusiveCare,
  },
  created() {
    this.getPersonInfoAndTicketsType();
  },
  // created() {
  //   this.getPersonInfoAndTicketsType();
  // },
  methods: {
    getPersonInfoAndTicketsType() {
      let params = {
        userRightId: this.$route.query.id,
      };
      this.$http
        .post(
          `/healthbusiness/health-tickets/getPersonInfoAndTicketsType`,
          params
        )
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          console.log('res', res);
          let data = res.data;
          // 权益卡信息处理
          let dataForm = {};
          dataForm.rightId = data.rightId;
          dataForm.rightClassify = 1;
          dataForm.userRightId = this.$route.query.id;
          dataForm.rightCode = data.rightCode;
          dataForm.rightName = data.rightName;
          dataForm.aliasRightName = data.aliasRightName;
          dataForm.packageName = data.packageName;
          this.dataForm = { ...dataForm };

          //   用户信息
          let userInfo = {};
          userInfo.proposerName = data.name;
          userInfo.certificateType = data.certificateType;
          userInfo.certificateNo = data.certificateNo;
          userInfo.mobile = data.mobile;
          this.userInfo = { ...userInfo };
          this.userContact = data.contactsEntityList;

          switch (dataForm.rightId) {
            case '58': //初级护理
            case '59': //中级护理
            case '60': //高级护理
            case '61': //专业级护理
            case '62': //（包月）初级护理
            case '63': //（包月）中级护理
            case '64': //（包月）高级护理
            case '65': //（包月）专业级护理
              this.showExclusiveCare = true;
              this.$refs.exclusiveCare.initChildFn(this.rightInfo, dataForm);
              this.$refs.basicUser.initChildFn(
                userInfo,
                data.contactsEntityList
              );
              break;
            default:
              this.$refs.basicApplications.initChildFn(
                this.rightInfo,
                dataForm
              );
              this.$refs.basicImage.initChildFn(dataForm);
              this.$refs.basicUser.initChildFn(
                userInfo,
                data.contactsEntityList
              );
          }
          //   this.dataForm.userRightId = this.$route.query.id
          //   let desc = res.data.aliasRightDesc
          //   this.dataForm.aliasRightDesc = desc.replace(/<\/?.+?>/g,"").replace(/ /g,"")
          //   用户信息处理
          // let
        })
        .catch(() => {});
    },
    methodsUserChange(childItem) {
      console.log('methodsUserChange', childItem);
      this.userInfo = { ...childItem };
    },
    methodsChange(childItem) {
      console.log('methodsChange', childItem);
      this.rightInfo = { ...childItem };
    },
    methodsImgChange(childItem) {
      this.imgList = childItem;
      console.log('methodsImgChange', this.imgList);
    },
    onSubmits() {
      let params = {};
      params.rightId = this.dataForm.rightId;
      params.rightClassify = this.dataForm.rightClassify;
      params.userRightId = this.dataForm.userRightId;
      params.rightCode = this.dataForm.rightCode;
      params.rightName = this.dataForm.rightName;
      params.aliasRightName = this.dataForm.aliasRightName;
      params.packageName = this.dataForm.packageName;
      //   用户信息
      params.proposerName = this.userInfo.proposerName;
      params.certificateType = this.userInfo.certificateType;
      params.certificateNo = this.userInfo.certificateNo;
      params.mobile = this.userInfo.mobile;
      //   权益字段信息
      params.applyHospitalStartDate = this.rightInfo.applyHospitalStartDate;
      params.applyHospitalEndDate = this.rightInfo.applyHospitalEndDate;
      params.applyHospital = this.rightInfo.applyHospital;
      params.applyDepartment = this.rightInfo.applyDepartment;
      params.province = this.rightInfo.province;
      params.city = this.rightInfo.city;
      params.district = this.rightInfo.district;
      params.illnessNote = this.rightInfo.illnessNote;
      params.medicareCard = this.rightInfo.medicareCard;
      params.address = this.rightInfo.address;
      params.applyNursingDays = this.rightInfo.applyNursingDays;
      params.applyNursingRank = this.rightInfo.applyNursingRank;
      params.serveType = this.rightInfo.serveType;
      params.therapyAddress = this.rightInfo.therapyAddress;
      params.mailingAddress = this.rightInfo.mailingAddress;
      params.contactName = this.rightInfo.contactName;
      params.contactMobile = this.rightInfo.contactMobile;
      params.appealDesc = this.rightInfo.appealDesc;
      params.illnessNote = this.rightInfo.illnessNote;
      params.doctorName = this.rightInfo.doctorName;
      params.payBank = this.rightInfo.payBank;
      params.payBankCard = this.rightInfo.payBankCard;
      params.payName = this.rightInfo.payName;
      params.applyAdvanceMoney = this.rightInfo.applyAdvanceMoney;
      //   影像件
      params.imgList = this.imgList;
      let rules = rulesRight(params);
      let ruleImg = imgRules(params);
      console.log('ruleImg', ruleImg);
      if (rules) {
        return this.$message.error(rules);
      }
      if (ruleImg) {
        return this.$message.error(ruleImg);
      }
      // return
      this.$http
        .post(`/healthbusiness/health-tickets/save`, params)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }

          console.log('res', res);
          this.$message({
            message: '提交成功',
            type: 'success',
            duration: 500,
            onClose: () => {
              this.$router.push({
                path: '/healthtickets',
              });
            },
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.add_service {
  .el-card {
    border-top: 3px solid rgba(3, 165, 151, 0.616);
  }
  .tiShiImg {
    width: 20px;
    height: 20px;
    margin-top: 10px;
  }
  .clearfix {
    font-size: 20px;
    font-weight: 700;
  }
  .addService_content {
    margin-top: 15px;
  }
  .submitButton {
    text-align: center;
    margin: 0 auto;
  }
}
</style>
